interface Address {
  zip?: string;
  latLng: { lat: number; lng: number };
}

export const browserLocation = () => {
  return new Promise<Address>((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetch(
            `/api/geo/reverse-geocode?${new URLSearchParams({
              latitude: `${position.coords.latitude}`,
              longitude: `${position.coords.longitude}`,
            }).toString()}`
          )
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw new Error();
            })
            .then((data) => {
              resolve({
                zip: data.postal_code,
                latLng: {
                  lat: data.coordinates[0],
                  lng: data.coordinates[1],
                },
              });
            })
            .catch(() => {
              reject(new Error('invalid geocoder results'));
            });
        },
        () => reject(new Error('could not access browser location'))
      );
    } else {
      reject(new Error('browser does not have geolocation API'));
    }
  });
};
