import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import { Component } from '@/types/interfaces';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import React from 'react';

export enum ButtonVariants {
  Primary = 'button-primary',
  Secondary = 'button-secondary',
  Tertiary = 'button-tertiary',
  Default = 'button-default',
  newPrimary = 'primary',
  newDefault = 'default',
}

export interface ButtonProps
  extends Component,
    Pick<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      'onClick' | 'disabled' | 'type' | 'onMouseDown' | 'onTouchEnd'
    > {
  variant?: ButtonVariants;
  loading?: boolean;
  children: React.ReactNode;
  icon?: {
    iconId: IconIds;
    className?: string;
    width?: number;
    height?: number;
    strokeColor?: string;
    fillColor?: string;
  };
  withArrow?: boolean;
  analyticsEvent?: AnalyticsEvent;
}

const Button: React.FC<ButtonProps> = ({
  className = '',
  variant = ButtonVariants.Default,
  loading = false,
  children,
  icon,
  withArrow = false,
  onClick = () => {},
  analyticsEvent,
  ...props
}) => {
  const { handleActionTracking } = useAnalytics();

  return (
    <button
      className={`button group relative ${variant} ${className}`}
      data-loading={loading}
      onClick={(e) => {
        if (analyticsEvent) {
          handleActionTracking(analyticsEvent);
        }
        onClick(e);
      }}
      {...props}
    >
      {icon && (
        <Icon
          {...icon}
          className={`absolute left-[30px] h-[24px] w-[24px] ${
            icon.className || ''
          }`}
        />
      )}
      <span className="relative">
        {children}
        {variant !== ButtonVariants.Tertiary &&
          withArrow &&
          !props.disabled && (
            <ChevronRightIcon
              className={`absolute left-full top-1/2 translate-x-0 translate-y-[-50%] opacity-0 transition-all group-hover:translate-x-[100%] group-hover:opacity-100 group-focus:translate-x-[100%] group-focus:opacity-100`}
              stroke={icon?.fillColor}
            />
          )}
      </span>
    </button>
  );
};

export default Button;
