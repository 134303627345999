// get query data from url
// return a function that redirects page with new query data
import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';

export const useSearchQuery: <T extends ParsedUrlQuery, Q = T>(
  path?: string
) => {
  data: Q & T;
  next: (
    data: Required<T> & Partial<Q>,
    cleanQuery?: boolean,
    as?: string
  ) => void;
} = <Q>(path?: string) => {
  const router = useRouter();

  const data = router.query as Q;
  return {
    data,
    next: (newData, cleanQuery, as) => {
      const newUrl = cleanQuery
        ? {
            pathname: path ?? router.pathname,
            query: newData,
          }
        : {
            pathname: path ?? router.pathname,
            query: { ...router.query, ...newData },
          };

      // replace query to save history
      router
        .replace(
          { pathname: router.pathname, query: newUrl.query },
          undefined,
          { shallow: true }
        )
        .then(() => {
          return router.push(newUrl, as, { shallow: false });
        });
    },
  };
};
